import React from 'react';
import jwtDecode, { JwtPayload } from "jwt-decode";

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem('AdminToken');
  // JWT decode & check token validity & expiration.
  try {
    const decoded = jwtDecode<JwtPayload>(token)
    let date = new Date(decoded.exp * 1000)
    if (new Date() > date) {
      localStorage.removeItem('AdminToken');
    }
    return new Date() <= date
  } catch (e) {
    console.log(e)
    localStorage.removeItem('AdminToken');
    return false
  }
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  function authenticate(token, cb) {
    makeAuthenticated(true);
    localStorage.setItem('AdminToken', token);
    setTimeout(cb, 100); // fake async
  }
  function signout(cb) {
    makeAuthenticated(false);
    localStorage.removeItem('AdminToken');
    setTimeout(cb, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
