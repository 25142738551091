import React, {useCallback, useEffect, useState} from 'react';

import {
    Row, Col,
} from '../../components/FlexBox/FlexBox';
import Input from '../../components/Input/Input';
import Button, {KIND} from '../../components/Button/Button';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Select } from 'baseui/select';
import { Checkbox } from 'baseui/checkbox';
import {Form} from "../DrawerItems/DrawerItems.style";
import DrawerBox from "../../components/DrawerBox/DrawerBox";
import {FormFields, FormLabel, TitleIcon} from "../../components/FormFields/FormFields"
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useDrawerDispatch} from "../../context/DrawerContext";

export default function Referral() {

    const dispatch = useDrawerDispatch();
    const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [
        dispatch,
    ]);

    const { register, handleSubmit } = useForm();

    const [amount, setAmount] = useState(null);

    //minimum price the user needs to buy in order to use this code
    const [minimumPurchaseLimit,setMinimumPurchaseLimit] = useState(null);

    const [percent, setPercent] = useState(null);


    const [active, setActive] = useState<boolean>(false);

    const [code, setCode] = useState(null);

    const [incentiveType,setIncentiveType] = useState(null);

    const [referralCount,setReferralCount] = useState();

    // graphql get mutation
    const GET_REFERRAL = gql`
    query getReferralCoupon{
        getReferralCoupon {
            name
            discountAmount
            referralCount
            isActive
            incentiveType
            minimumPurchaseLimit
        }
    }
    `

    //graphql edit mutaion
    const Edit_REFERAL = gql`
    
      mutation updateReferralCoupon($referralCount:Float!,$discountAmount:Float!, $isActive:Boolean!, $incentiveType:String!, $minimumPurchaseLimit:Float!) {
                updateReferralCoupon(referralCount:$referralCount,discountAmount:$discountAmount,  isActive:$isActive, minimumPurchaseLimit:$minimumPurchaseLimit,incentiveType:$incentiveType) {
                discountAmount
                isActive
        }
      }
    `;

    //retrieve the information of the referral coupon
    const { data, error, refetch, loading } = useQuery(GET_REFERRAL, {
        variables: { search: "ReferralCreditCoupon"}});
    //console.log(data)
    //useEffect on data, when info is retrieved, load it into the states
    useEffect(() => {
        //console.log(data)
        //console.log(error)
        if (data) {

            setAmount(data.getReferralCoupon.discountAmount)

            setCode(data.getReferralCoupon.name)

            setActive(data.getReferralCoupon.isActive)

            setMinimumPurchaseLimit(data.getReferralCoupon.minimumPurchaseLimit)

            setIncentiveType(data.getReferralCoupon.incentiveType)

            setReferralCount(data.getReferralCoupon.referralCount)
        }
    }, [data]);


    //available active options
    const incentiveOptions = [
        { value:"Single Incentive", label:"Single Incentive" },
        //{ value:"Double Incentive", label:"Double Incentive" }
    ];

    //use update mutation
    const [updateCoupon] = useMutation(Edit_REFERAL)

    //when the user submits,  send update to backend
    const onSubmit = async data => {
        try {
            update(amount,active,incentiveType,minimumPurchaseLimit,referralCount)
        } catch (e) {
            //console.log(e)
        }
    };

    //async update method
    const update=async (amount,active,incentiveType,minimumPurchaseLimit,referralCount) => {
        //console.log(couponId+" "+amount+" "+active+" "+incentiveType+" "+minimumPurchaseLimit)
        try {
            const result = await updateCoupon({variables: {referralCount:referralCount,discountAmount:amount, isActive:active, incentiveType:incentiveType, minimumPurchaseLimit:minimumPurchaseLimit}});
            if (result) {
                toast.success(`🦄  Referral Coupon has been successfully updated.`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                closeDrawer();
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            }
        } catch (e) {
            console.log(e)
            toast.error(`🦄 SomeThing Went Wrong`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    return(
        <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <DrawerBox>
                <h1>Referral Campaign</h1>
                <FormFields>
                    <FormLabel>Coupon Code<TitleIcon>*</TitleIcon></FormLabel>
                    <Input inputRef={register({ required: true })} name="serialNumber" inputProps={{ readOnly: true }} disabled={true} value={code}/>
                </FormFields>


                <FormFields>
                    <FormLabel>Referral Reward ${!percent ? <TitleIcon>*</TitleIcon> : null}</FormLabel>
                        <Input
                            type="number"
                            step={0.01}
                            name="discount_in_amount"
                            onChange={(e) => setAmount(e.target.value)}
                            disabled={percent}
                            value = {amount}
                        />
                </FormFields>

                <FormFields>
                    <FormLabel>Referral Count  <TitleIcon>*</TitleIcon></FormLabel>
                    <Input
                        type="number"
                        name="referral_count"
                        onChange={(e) => setReferralCount(e.target.value)}
                        value = {referralCount}
                    />
                </FormFields>


                <FormFields>
                    <FormLabel>isActive <TitleIcon>*</TitleIcon></FormLabel>
                    <div className="switch" onClick={() => setActive(!active)}>
                        <input type="checkbox" checked={active} />
                            <span className="slider round"></span>
                    </div>
                </FormFields>

                <FormFields>
                    <FormLabel>Incentive Types <TitleIcon>*</TitleIcon></FormLabel>
                    {incentiveOptions.map((option) => (
                        <Checkbox
                            key={option.label}
                            checked={incentiveType === option.value}
                            onChange={() => setIncentiveType(option.value)}
                            disabled={true}

                        >
                            {option.label}
                        </Checkbox>
                    ))}
                </FormFields>

                <Button type="submit">Save</Button>

            </DrawerBox>



        </Form>
    );
}