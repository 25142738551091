// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const PROFILE = '/profile';

export const CATEGORY = '/category';
export const LOGIN = '/login';
export const FORGOT = '/forgot';
export const RESET_CODE = '/reset';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const REFERRAL = '/referral';
export const BRANDS = '/brands';
export const ADMINS = '/admins';
export const COUPONS = '/coupons';
export const GIFT_CARDS = '/gift-cards';
export const SETTINGS = '/settings';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const THIRD_PARTY_APPS = '/third-party-apps'

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
